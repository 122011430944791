<template>
  <div>
    <CCard>
      <CTabs>
        <CTab title="Vendita prodotti" v-if="showProducer">
          <ConnectionsProducer />
        </CTab>
        <!-- <CTab title="Produttori (mercato)">
          <ConnectionsMarketProducer />
        </CTab>
        <CTab title="Isole (mercato)">
          <ConnectionsMarketIsland />
        </CTab> -->
        <CTab title="Consegna prodotti" v-if="showIsland">
          <ConnectionsIsland />
        </CTab>
      </CTabs>
    </CCard>
  </div>
</template>

<script>
// import { CircleStencil, Cropper } from "vue-advanced-cropper";
import { DataManager, Query } from "@syncfusion/ej2-data";
import { GetDataManager, ApiCall } from "../../../ds/index";
import DrfAdaptor from "../../../http/EJ2_adapter";

import ConnectionsProducer from "./ConnectionsProducer";
import ConnectionsIsland from "./ConnectionsIsland";
import ConnectionsMarketProducer from "./ConnectionsMarketProducer";
import ConnectionsMarketIsland from "./ConnectionsMarketIsland";
import { apiUrl } from "../../../http";
// import ProfileLogo from "./ProfileLogo";
// import ProfileImages from "./ProfileImages";

export default {
  name: "ProfileData",
  data() {
    return {
      activeTab: 0,
      wallet: null,
      role: null,
    };
  },
  // mounted() {
  //   // this.getFamily();
  //   this.getWallet();
  //   // this.getProducer();

  //   let self = this;
  //   // Update propduct Info
  //   ApiCall(
  //     GetDataManager("auth_frole", [], this.$store.state.role.id),
  //     new Query(),
  //     function (e) {
  //       // self.pp = e.result;
  //       self.role = e.result;
  //     }
  //   );
  // },
  computed: {
    showProducer() {
      if ([1, 2, 3].includes(this.$store.state.role.r_type)){
        return true
      }
      return false;
    },
    showIsland() {
      if ([1, 3, 4].includes(this.$store.state.role.r_type)){
        return true
      }
      return false;
    },
  },
  // computed: {
  //   role() {
  //     return this.$store.state.role;
  //   },
  // },
  // methods: {
  //   // getFamily() {
  //   //   const dm = new DataManager({
  //   //     url:
  //   //       LOONITY_BASE_API_URL +
  //   //       "/api/auth/roles/" +
  //   //       this.$store.state.role.id +
  //   //       "/family/" +
  //   //       this.$store.state.role.family_id,
  //   //     adaptor: new DrfAdaptor(),
  //   //     crossDomain: true,
  //   //   });
  //   //   dm.executeQuery(new Query()).then((e) => {
  //   //     // this.family = e.result;
  //   //     let role = this.role;
  //   //     role.family = e.result;
  //   //     console.log(role);
  //   //     this.$store.commit("setRole", role);
  //   //   });
  //   // },
  //   getWallet() {
  //     const dm = new DataManager({
  //       url: apiUrl(
  //         `/api/auth/family/${this.$store.state.role.family_id}/wallet/`
  //       ),
  //       adaptor: new DrfAdaptor(),
  //       crossDomain: true,
  //     });
  //     dm.executeQuery(new Query()).then((e) => {
  //       this.wallet = e.result;
  //     });
  //   },
    // getProducer() {
    //   const dm = new DataManager({
    //     url:
    //       LOONITY_BASE_API_URL +
    //       "/api/auth/roles/" +
    //       this.$store.state.role.id +
    //       "/producer/" +
    //       this.$store.state.role.id,
    //     adaptor: new DrfAdaptor(),
    //     crossDomain: true,
    //   });
    //   dm.executeQuery(new Query()).then((e) => {
    //     this.producer = e.result;
    //     let role = this.role;
    //     role.producer = e.result;
    //     this.$store.commit("setRole", role);
    //   });
    // },
  // },
  components: {
    ConnectionsProducer,
    ConnectionsIsland,
    ConnectionsMarketProducer,
    ConnectionsMarketIsland,
    // RoleImageRect,
    // RoleImageSquare,
    // RoleData,
    // RoleDataFamily,
    // RoleDataProducer,
    // RoleImageFamily,
    // ProfileImages,
    // ProfileInfo,
    // ProfileLogo,
  },
};
</script>
